import { navigate } from "gatsby-link";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components"
import { TextField, Button, Card, CardContent } from "@material-ui/core"
import { useLocation } from '@reach/router';
import '../encuesta.css'; 

const StyledTextField = styled(TextField)`
  ${props =>
        css`
      input {
        color: #000 !important;
      }
      div:after, div:before {
        border-bottom: none;
      }
      div {
        color: #000 !important;
        background-color: #ffffff;
        box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        &:hover {
            background-color: #ffffff;
            box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        }
      }
      label {
        color: #000000 !important;
        font-family: Raleway;
      }
      textarea {
        color: #000 !important;
      }
    `}
`

const URL = process.env.GATSBY_API_URL
//const URL = "http://localhost:6001"

const FormEncuestaPreguntas = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
     const id_encuesta = searchParams.get('id_encuesta');
    const id_template = searchParams.get('id_template');
    const token = searchParams.get("token")

    const [preguntas, setPreguntas] = useState()
    const [error, setError] = useState(false)
    const [clientForm, setClientForm] = useState({});
    const [enviarStatus, setEnviarStatus] = useState("Enviar")

    const [encuestaFueCompletada, setEncuestaFueCompletada] = useState(false)

    const [preguntaActual, setPreguntaActual] = useState(0)

    /* Estados para mostrar textarea si da al checkbox otro */
    const [textoOtro, setTextoOtro] = useState();
    const [mostrarTextFieldOtro, setMostrarTextFieldOtro] = useState(false);

    const handleSiguiente = () => {
        setPreguntaActual(preguntaActual+1)
    }

    const handleAtras = () => {
        setPreguntaActual(preguntaActual-1)
    }

    useEffect(() => {
        if (
            clientForm &&
            clientForm.preguntas &&
            clientForm.preguntas[preguntaActual] &&
            clientForm.preguntas[preguntaActual].respuesta_texto &&
            clientForm.preguntas[preguntaActual].respuesta_texto.includes("F")
        ) {
            setMostrarTextFieldOtro(true);
        } else {
            setMostrarTextFieldOtro(false);
        }
    }, [preguntaActual]);
    

    useEffect(() => {
        const fetchData = async () => {
          try {
            //Solicitamos al back todas las preguntas correspondientes. 
//Enviamos al back tanto el token, como el id_template y el id_encuesta para verificar si el usuario toco algo en la URL (sea que modifico el id de su encuesta o el id de su template) antes de mostrarle las preguntas.

            const response = await fetch(`${URL}/survey_template/${id_template}`,  {headers: {Authorization: `Bearer ${token} ${id_encuesta}`}});
            const json = await response.json()
            //console.log("hubo error? ", json)
            
            if(json.data == "Encuesta ya fue completada"){
                setEncuestaFueCompletada(true)
            }

            if (json.ok) {
                //Modifico las preguntas que tengan valoracion numerica sacando el texto "En una escala de 1 a 10, donde 10 es el mayor y 1 el menor marque su respuesta." que tiene 80 caracteres
                json.data = json.data.map((data)=>{
                    if(data.tipo_pregunta === 'N'){
                        return {
                            id_template_pregunta: data.id_template_pregunta,
                            id_template: data.id_template,
                            tipo_pregunta: data.tipo_pregunta,
                            pregunta: data.pregunta.substring(0, data.pregunta.length -80),
                            opciones_pregunta: data.opciones_pregunta
                        }
                    }else{
                        return data
                    }
                })

                setPreguntas(json); //Seteo las preguntas con lo que me contesto el backend, si me contesto ok.
                setError(false);

                const newArray = json.data.map((data) => ({
                    tipo_pregunta: data.tipo_pregunta,
                    pregunta: data.pregunta,
                    opciones_pregunta: data.opciones_pregunta,
                    respuesta_numerica: null,
                    respuesta_texto: null,
                }));
    
                const dataObject = {
                    id_encuesta: Number(id_encuesta),
                    preguntas: newArray,
                };

                // console.log(`dataObject: `, dataObject)

                const newArrayTextosOtros = json.data.map((data, index) => {
                    if (data.tipo_pregunta === 'O') {
                        return {
                            id_pregunta: index + 1,
                            respuesta_texto: null,
                            status: false
                        };
                    } else {
                        return null;
                    }
                });
                // console.log(newArrayTextosOtros);
                setTextoOtro(newArrayTextosOtros)
                setClientForm(dataObject)
            } else {
                setError(true)
                //console.error('Error fetching data: Response not ok');
            }
          } catch (error) {
            console.error('Error fetching data: ', error);
          }
        };
        fetchData();
    }, []); 

    const onSubmitHandler = async () => {
        try {
            setEnviarStatus("Enviando")

            const updatedPreguntas = clientForm.preguntas.map((pregunta, index) => {
                if (pregunta.respuesta_texto && pregunta.respuesta_texto.includes('F')) {
                    return {
                        ...pregunta,
                        respuesta_texto: pregunta.respuesta_texto.replace("F", `F:${textoOtro[index].respuesta_texto}`)
                    };
                }
                return pregunta;
            });
    
            const updatedClientForm = {
                ...clientForm,
                preguntas: updatedPreguntas
            };

            const response = await fetch(URL+'/form/clientForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedClientForm)
            });

            // console.log("Primero: ", JSON.stringify(updatedClientForm));
            
            const responseData = await response.json();
            // console.log("Segundo: ", responseData);
            
            if (responseData.ok === true) {
                // console.log('Form survey submitted successfully.');
                navigate('/encuesta-de-satisfaccion/encuesta-agradecimiento');
            } else {
                // console.log('Form survey submission failed.');
            }
        } catch (error) {
            console.error('Error submitting form survey:', error);
        } finally {
            setEnviarStatus("Enviar")
        }
    };

    const handleRadioChange = (preguntaIndex, valorSeleccionado) => {
        const updatedClientForm = { ...clientForm };
        const preguntaActualObj = updatedClientForm.preguntas[preguntaActual];

        preguntaActualObj.respuesta_numerica = valorSeleccionado;
        setClientForm(updatedClientForm);
        // console.log(clientForm.preguntas[preguntaIndex]);
    };

    const handleCheckBoxChange = (preguntaIndex, valorSeleccionado) => {
        const updatedClientForm = { ...clientForm };
        const preguntaActualObj = updatedClientForm.preguntas[preguntaActual];

        if (preguntaActualObj.respuesta_texto === null) {
            preguntaActualObj.respuesta_texto = ''; 
        }
    
        const opcionesSeleccionadas = preguntaActualObj.respuesta_texto.split('-'); 
        const index = opcionesSeleccionadas.indexOf(valorSeleccionado);
        
        if (index === -1) {
            opcionesSeleccionadas.push(valorSeleccionado);  
        } else {
            opcionesSeleccionadas.splice(index, 1);  
        }

        const estaPresenteOpcionF = opcionesSeleccionadas.includes('F');
        setMostrarTextFieldOtro(estaPresenteOpcionF);

        if(!estaPresenteOpcionF) {
            setTextoOtro(prevState => ({
                ...prevState,
                [preguntaIndex]: {
                    ...prevState[preguntaIndex],
                    respuesta_texto: ""
                }
            }));
        }

        if (opcionesSeleccionadas[0] === '') {
            opcionesSeleccionadas.shift();
        }

        preguntaActualObj.respuesta_texto = opcionesSeleccionadas.join('-');  

        setClientForm(updatedClientForm);
        // console.log(clientForm.preguntas[preguntaIndex]);
    };

    const handleTextChange = (preguntaIndex, valorTipeado) => {
        const updatedClientForm = { ...clientForm };
        const preguntaActualObj = updatedClientForm.preguntas[preguntaActual];

        preguntaActualObj.respuesta_texto = valorTipeado;
        setClientForm(updatedClientForm);
        // console.log(clientForm.preguntas[preguntaIndex]);
    }

    const handleTextOtroChange = (idPregunta, texto) => {
        setTextoOtro(prevState => ({
            ...prevState,
            [idPregunta]: {
                ...prevState[idPregunta],
                respuesta_texto: texto
            }
        }));
    };
    
    // const barras = preguntas && Array.from({ length: preguntas.data.length }, (_, index) => (
    //     <div
    //         key={index}
    //         className={index < (preguntaActual+1) ? 'barra-pintada' : 'barra'}
    //     ></div>
    // ));
    const barras = preguntas && preguntas.data && Array.from({ length: preguntas.data.length }, (_, index) => {
        const cantidadPreguntas = preguntas.data.length > 12;
        const claseBase = cantidadPreguntas ? 'barra-grande' : 'barra-chica';
        const barraPintada = cantidadPreguntas ? 'barra-pintada barra-pintada-grande' : 'barra-pintada barra-pintada-chica';
        const claseFinal = index < (preguntaActual + 1) ? barraPintada : claseBase;
    
        return (
            <div
                key={index}
                className={`barra ${claseFinal}`}
            ></div>
        );
    });

    const opciones_pregunta = [
        {
          "opcion": "A",
          "descripcion": "Compromiso con la tarea."
        },
        {
          "opcion": "B",
          "descripcion": "Integración con el equipo del cliente."
        },
        {
          "opcion": "C",
          "descripcion": "Comunicación con el cliente."
        },
        {
          "opcion": "D",
          "descripcion": "Cumplimiento con los compromisos acordados."
        },
        {
          "opcion": "E",
          "descripcion": "Resolución de imprevistos."
        },
        {
          "opcion": "F",
          "descripcion": "Otro."
        }
    ]

    const shouldDisableButton = () => {
        if (
            clientForm &&
            clientForm.preguntas &&
            clientForm.preguntas[preguntaActual] &&
            (
                (clientForm.preguntas[preguntaActual].tipo_pregunta === 'N' && clientForm.preguntas[preguntaActual].respuesta_numerica === null) ||
                (clientForm.preguntas[preguntaActual].tipo_pregunta === 'O' && (clientForm.preguntas[preguntaActual].respuesta_texto === null || clientForm.preguntas[preguntaActual].respuesta_texto == "")) ||
                (clientForm.preguntas[preguntaActual].tipo_pregunta === 'A' && (clientForm.preguntas[preguntaActual].respuesta_texto === null || clientForm.preguntas[preguntaActual].respuesta_texto == ""))
            )
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div>
            <Styled>
                <div style={{ textAlign: 'center', padding: '1rem' }}>
                <h2 style={{ fontSize: '2rem', margin: '0.5rem 0' }}>Encuesta de Satisfacción de Clientes</h2>
                    <h4 style={{ color: 'grey', fontSize: '1.4rem', margin: '0', textAlign: 'center' }}>
                        Agradecemos el momento que nos brinda para ayudarnos a mejorar <br/>
                        respondiendo a las siguientes preguntas acerca de su experiencia con nosotros, <br/>
                        Global Hitss Argentina.
                    </h4>
                </div>
                <div className="ContactFormPreguntas">
                    <Card style={{ textAlign: 'center', width: '100%', maxWidth: '900px', padding: '1rem', margin: '1rem auto'}}>
                        {error && (
                            <CardContent>
                                <div style={{ textAlign: 'center' }}>
                                    <p style={{fontSize: '22px', color: 'grey', fontWeight: `700`}}>
                                        {encuestaFueCompletada ? "Encuesta ya fue respondida" : `Encuesta no encontrada`}
                                    </p>
                                </div>
                            </CardContent>
                        )}
                        {!error && preguntas && preguntas.data.length > 0 && clientForm && (
                            <CardContent>
                                {barras}
                                <p>{preguntaActual+1}/{preguntas.data.length}</p>
                                <p className="pregunta">{preguntas.data[preguntaActual].pregunta}</p>
                                {preguntas.data[preguntaActual].tipo_pregunta == 'N' && (
                                    <>
                                        <p className="subtitulo-escala-puntuacion">En una escala de 1 a 10, donde 10 es el mayor y 1 el menor marque su respuesta.</p>
                                        <div className="escala-container">
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((valor, index) => (
                                                <div key={index} className='escala-item'>
                                                    <input 
                                                        type="radio"
                                                        name={`${preguntas.data[preguntaActual].id_template_pregunta}_respuesta`}
                                                        id={`${preguntas.data[preguntaActual].id_template_pregunta}${valor}`}
                                                        value={valor}
                                                        onChange={(e) => handleRadioChange(preguntaActual, valor)}
                                                        checked={clientForm.preguntas && clientForm.preguntas[preguntaActual].respuesta_numerica == valor}
                                                    />
                                                    <label htmlFor={`${preguntas.data[preguntaActual].id_template_pregunta}${valor}`} style={{marginTop: '10px'}}>
                                                        <div style={{fontSize: '20px'}}>{valor}</div>
                                                    </label>
                                                </div>
                                            ))}
                                        </div> 
                                    </>
                                )}
                                {preguntas.data[preguntaActual].tipo_pregunta == 'O' && (
                                    <div>
                                        {opciones_pregunta.map((opcion, index) => (
                                            <div key={index} style={{ textAlign: 'left' }}>
                                            <input
                                                className="form-field"
                                                type="checkbox"
                                                name={`${preguntas.data[preguntaActual].id_template_pregunta}${opcion.opcion}`}
                                                id={`${preguntas.data[preguntaActual].id_template_pregunta}${opcion.opcion}`}
                                                onChange={(e) => {
                                                    handleCheckBoxChange(preguntaActual, e.target.value);
                                                }}
                                                checked={
                                                    clientForm &&
                                                    clientForm.preguntas &&
                                                    clientForm.preguntas[preguntaActual] &&
                                                    clientForm.preguntas[preguntaActual].respuesta_texto &&
                                                    clientForm.preguntas[preguntaActual].respuesta_texto.includes(opcion.opcion) ? true : false
                                                }
                                                value={opcion.opcion}
                                            />
                                            <label 
                                                htmlFor={`${preguntas.data[preguntaActual].id_template_pregunta}${opcion.opcion}`} 
                                                style={{marginLeft: '10px', fontSize: '18px'}}
                                            >
                                                {opcion.descripcion}
                                            </label>
                                            <br />
                                            </div>
                                        ))}
                                        {mostrarTextFieldOtro && (
                                            <div className="formControl">
                                            <StyledTextField
                                                
                                                placeholder='Si seleccionó "Otro", por favor, proporcione detalles'
                                                className="form-field"
                                                type="textarea"
                                                name="textareaOtro"
                                                id="textareaOtro"   
                                                value={textoOtro && textoOtro[preguntaActual].respuesta_texto ? textoOtro[preguntaActual].respuesta_texto : ''}
                                                onChange={(e) => handleTextOtroChange(preguntaActual, e.target.value)}
                                                fullWidth={true}
                                                autoFocus
                                            />
                                            </div>
                                        )}
                                    </div>
                                )}
                                {preguntas.data[preguntaActual].tipo_pregunta == 'A' && (
                                    <div>
                                        <div className="formControl">
                                            <StyledTextField
                                                placeholder='Por favor, proporcione detalles'
                                                type="textarea"
                                                name={preguntas.data[preguntaActual].id_template_pregunta}
                                                id={preguntas.data[preguntaActual].id_template_pregunta}
                                                fullWidth={true}
                                                value={clientForm && clientForm.preguntas && clientForm.preguntas[preguntaActual].respuesta_texto ? clientForm.preguntas[preguntaActual].respuesta_texto : ''}
                                                onChange={(e) => handleTextChange(preguntaActual, e.target.value)}
                                                size="small"
                                                variant="filled"
                                                autoFocus
                                            />
                                        </div>
                                    </div>
                                )}
                                <div>
                                    {preguntaActual+1 < preguntas.data.length ? (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            fullWidth={true}
                                            className="submit"
                                            style={{
                                                backgroundColor: shouldDisableButton() ? 'rgba(0, 0, 0, 0.1)' : '#269094',
                                                flex: 1
                                            }}
                                            disabled={shouldDisableButton()}
                                            onClick={handleSiguiente}
                                        >
                                            Siguiente
                                        </Button>
                                        ) : (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth={true}
                                            className="submit"
                                            style={{ backgroundColor: '#269094', flex: 1, color: '#fff' }}
                                            onClick={onSubmitHandler}
                                        >
                                            {enviarStatus}
                                        </Button>
                                    )}

                                    {preguntaActual >= 1 && (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            fullWidth={true}
                                            className="submit"
                                            style={{ 
                                                backgroundColor: enviarStatus === 'Enviando' ? 'rgba(0, 0, 0, 0.1)' : '#fff',
                                                color: enviarStatus === 'Enviando' ? '#fff' : '#269094',
                                                flex: 1, 
                                                marginBottom: '10px' 
                                            }}
                                            onClick={handleAtras}
                                            disabled={enviarStatus == "Enviando"}
                                        >
                                            Atrás
                                        </Button>
                                    )}
                                </div>
                            </CardContent>
                        )}
                    </Card>
                    
                </div>
            </Styled>
        </div>
    )
}

const Styled = styled.div`
 .title {
    width: 100%;
    padding: 0px 70px;
    color: #000000;
    font-family: Questrial;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: 0;

    
    @media (max-width: 780px) {
        padding: 0px 10px;
    }
 }

 .linkAccess{
    margin: 0 auto;
    height: 67px;
    max-width: 500px;
    border-radius: 33.5px;
    background-color: #24CC65;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .icon {
        width: 70px;
        margin-left: 5px;
        svg {
            height: 40px;
            width: 71.11px;
        }
    }

    .desc {

        padding-right: 15px;

        p {
            color: #FFFFFF;
            font-family: "Century Gothic";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            margin: 0;
            text-decoration: none;

            a {
                color: white !important;
            }
        }
    }
 }

 .formControl{
     min-height: 65px;     

     .form-field:after {
        border-bottom-color: #f44336 !important;
    }
 }
 .submit{
    color: #FFFFFF;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 32px;
    height: 40px;
    background-color: #A5BE01;
 }

 .form {
    margin-bottom: 0;
 }
`

export default FormEncuestaPreguntas;
